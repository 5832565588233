<template>
  <div class="page_notice" id="notice_list">
    <!--    <div class="warp">-->
    <!--      <div class="container">-->
    <!--        <div class="row">-->
    <!--          <div class="col-12">-->
    <!--            <div class="car_notice">-->
    <!--              &lt;!&ndash; 公告列表 &ndash;&gt;-->
    <!--              <div class="notice_list">-->
    <!--                <div class="notice_list_title">-->
    <!--                  <span class="title">{{$page_title("/notice/list") || "公告列表"}}</span>-->
    <!--                </div>-->
    <!--                <router-link-->
    <!--                  class="notice_block"-->
    <!--                  v-for="(o, i) in list"-->
    <!--                  :key="i"-->
    <!--                  :to="'/notice/details?' + 'notice_id' + '=' + o.notice_id"-->
    <!--                >-->
    <!--                  <div class="notice_title ellipsis_2">-->
    <!--                    {{ o.title }}-->
    <!--                  </div>-->
    <!--                  <div class="notice_create_time">-->
    <!--                    {{ o.create_time| formatDate }}-->
    <!--                  </div>-->
    <!--                </router-link>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="row">-->
    <!--          &lt;!&ndash; 分页器 &ndash;&gt;-->
    <!--          <div class="col overflow-auto flex_cc">-->
    <!--            <b-pagination-->
    <!--                v-model="query.page"-->
    <!--                :total-rows="count"-->
    <!--                :per-page="query.size"-->
    <!--                @change="goToPage"-->
    <!--            />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="banner">
    </div>
    <div class="bqy">
      <div class="bqybt">
        <div class="jjbt" @click="changeTab(1)"> 企业简介
          <!--          <el-button type="text" @click="changeTab(1)">企业简介</el-button>-->
        </div>
        <div class="gcbt" @click="changeTab(2)"> 现代化供应体系
          <!--          <el-button type="text" @click="changeTab(2)">现代化工厂</el-button>-->
        </div>
        <div class="jybt" @click="changeTab(3)"> 董事长寄语
          <!--          <el-button type="text" @click="changeTab(3)">董事长寄语</el-button>-->
        </div>
      </div>
      <div class="bqy_content">
        <div class="all1" v-if="all1">
        </div>
        <div class="all2" v-if="all2">
        </div>
        <div class="all3" v-if="all3">
        </div>
      </div>
      <div class="fzlc">
        <!--        <div class="gswh_title">-->
        <!--          <p>发展历程</p>-->
        <!--          <el-divider></el-divider>-->
        <!--        </div>-->
      </div>
      <div class="gswh">
        <div class="gswh_title">
          <p>徐志馍文化</p>
          <el-divider></el-divider>
        </div>
        <div class="gswh_content">
          <div class="gswh_hover"></div>
          <div class="gswh_hover"></div>
          <div class="gswh_hover"></div>
        </div>
      </div>

      <div class="ppry">
        <div class="ppry_title">
          <p>品牌荣誉</p>
          <el-divider></el-divider>
        </div>
        <div class="ppry_content">
        </div>
      </div>

      <div class="plgd">
        <div class="gswh_title">
          <p>好评如潮</p>
          <el-divider></el-divider>
        </div>
        <div class="plgd_content">
          <el-carousel :interval="4000" type="card">
            <el-carousel-item v-for="item in list" :key="item">
              <div class="html_img" v-html="item.content"></div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="jmly">
      <router-link to="/consultation_information/edit">
        <div class="jmly_to"></div>
      </router-link>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/page.js";

export default {
  mixins: [mixin],
  data() {
    return {
      url_get_list: "~/api/notice/get_list",
      list: [],
      query: {
        title: "",
        page: 1,
        size: 10,
      },
      // 页面数
      count_pages: 1,
      //控制标签页切换
      all1: true,
      all2: false,
      all3: false,
    };
  },
  created() {
    console.log("查看");
    console.log(this.list);
  },
  methods: {
    /**
     * 获取公告后
     * @param {Object} json 响应对象
     */
    // get_list_after(json) {
    //   this.count_pages = Math.ceil(json.result.count / this.query.size);
    // },
    // goToPage(v) {
    //   this.query.page = v;
    //   this.goToNew(v)
    // },
    changeTab(tab) {
      if (tab == 1) {
        this.all1 = true;
        this.all2 = false;
        this.all3 = false;
      } else if (tab == 2) {
        this.all1 = false;
        this.all2 = true;
        this.all3 = false;
      } else {
        this.all1 = false;
        this.all2 = false;
        this.all3 = true;
      }
    }
  },
};
</script>

<style scoped>
.flex_cc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ellipsis_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.container {
  min-height: 800px;
}

.notice_block {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 5px;
}

.notice_title {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notice_list_title {
  margin: 0 0.5rem;
  background-color: #d2d2d2;
  color: white;
  display: flex;
  height: 4rem;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  padding-left: 1rem;
}

.banner {
  width: 100%;
  height: 85vh;
  margin-top: 50px;
  //background-image: url("../../assets/images/gybg.jpg");
  //background-size: cover;
  //background-position: center center;
  //background-repeat: no-repeat;
  background: url("../../assets/images/gyxzm.jpg") no-repeat;
  background-size: 100% 100%;
}

.bqy {
  width: 100%;
}

.bqybt {
  width: 100%;
  height: 10vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 999;
}

.jjbt {
  width: 20%;
  height: 100%;
  color: #FFFFFF;
  background-color: #c3152c;
  margin-right: 10px;
  margin-top: -5vh;
  font-family: 微软雅黑;
  font-size: xx-large;
  font-weight: bolder;
  cursor: pointer;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;
}

.jjbt:hover {
  background-color: #FFFFFF;
  color: #c3152c;
}

.gcbt {
  width: 20%;
  height: 100%;
  color: #FFFFFF;
  background-color: #c3152c;
  text-align: center;
  margin-right: 10px;
  margin-top: -5vh;
  font-family: 微软雅黑;
  font-size: xx-large;
  font-weight: bolder;
  cursor: pointer;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;
}

.gcbt:hover {
  background-color: #FFFFFF;
  color: #c3152c;
}

.jybt {
  width: 20%;
  height: 100%;
  color: #FFFFFF;
  background-color: #c3152c;
  text-align: center;
  margin-top: -5vh;
  font-family: 微软雅黑;
  font-size: xx-large;
  font-weight: bolder;
  cursor: pointer;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;
}

.jybt:hover {
  background-color: #FFFFFF;
  color: #c3152c;
}

.el-button--text {
  font-family: KaiTi;
  color: #FFFFFF;
  font-size: 6vh;
}

.el-button--text:hover {
  color: #c3152c;
}

.bqy_content {
  width: 100%;
  height: 700px;
  margin-top: -10vh;
}

.bqy_content .all1 {
  width: 100%;
  height: 100%;
  display: flex;
  background: url("../../assets/images/qyjjbgb.jpg") no-repeat;
  background-size: 100% 120%;
}

.bqy_content .all2 {
  width: 100%;
  height: 100%;
  display: flex;
  background: url("../../assets/images/xdhgc.jpg") no-repeat;
  background-size: 100% 100%;
}

.bqy_content .all3 {
  width: 100%;
  height: 100%;
  display: flex;
  background: url("../../assets/images/dszjy.jpg") no-repeat;
  background-size: 100% 100%;
}

.fzlc {
  width: 100%;
  height: 560px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: url("../../assets/images/fzlcbg.jpg") no-repeat;
  background-size: 100% 100%;
}

.gswh {
  width: 100%;
  height: 700px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  //background-color: #FFFFFF;
  background: url("../../assets/images/numbg.jpg") no-repeat;
  background-size: 100% 100%;
}

.ppry {
  width: 100%;
  height: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  //background-color: #FFFFFF;
  background: url("../../assets/images/numbg.jpg") no-repeat;
  background-size: 100% 100%;
}

.gswh_title {
  width: 80%;
  height: 10%;
  color: black;
  font-family: 微软雅黑;
  font-size: 3vw;
  font-weight: bolder;
}
.ppry_title {
  width: 80%;
  height: 10%;
  color: black;
  font-family: 微软雅黑;
  font-size: 3vw;
  font-weight: bolder;
  margin-bottom: 25px;
}

.gswh_content {
  width: 80%;
  height: 80%;
  background: url("../../assets/images/gswhbg.jpg") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.ppry_content {
  width: 80%;
  height: 85%;
  background: url("../../assets/images/ppry.jpg") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.gswh_hover {
  width: 33.33%;
  height: 100%;
}

.gswh_hover:hover {
  background-color: rgba(231, 0, 18, 0.15);
}

.plgd {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  //background-color: #FFFFFF;
  background: url("../../assets/images/numbg.jpg") no-repeat;
  background-size: 100% 100%;
}

.plgd_content {
  width: 60%;
  height: 80%;
}

.html_img {
  width: 100%;
  height: 100%;
  position: relative;
}

.html_img >>> img {
  width: 100%;
  height: auto;
}

.el-carousel__item:nth-child(2n) {
  background-color: #ffffff;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #ffffff;
}

.jmly {
  height: 68px !important;
  width: 130px;
  z-index: 11111112;
  top: 20%;
  right: 0px;
  position: fixed;
  margin-top: -34px;
  margin-left: 0px;
}

.jmly_to {
  height: 100%;
  width: 100%;
  background: url("../../assets/images/join/hzzx.png") no-repeat;
  background-size: 100% 100%;
}
/deep/ .el-carousel__container {
  height: 80vh;
}

@media (max-width: 1081px) {
  .banner {
    width: 100%;
    height: 40vh;
    margin-top: 50px;
    background: url("../../assets/images/gyxzm.jpg") no-repeat;
    background-size: 100% 100%;
  }

  .jmly_to {
    height: 50%;
    width: 50%;
    background: url("../../assets/images/join/hzzx.png") no-repeat;
    background-size: 100% 100%;
  }

  .jmly {
    height: 68px !important;
    width: 130px;
    z-index: 11111112;
    top: 20%;
    right: -45px;
    position: fixed;
    margin-top: -34px;
    margin-left: 0px;
  }

  .jjbt {
    width: 19%;
    height: 48%;
    color: #FFFFFF;
    background-color: #c3152c;
    margin-right: 10px;
    margin-top: -3vh;
    font-family: 微软雅黑;
    font-weight: bolder;
    font-size: medium;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gcbt {
    width: 25%;
    height: 48%;
    color: #FFFFFF;
    background-color: #c3152c;
    text-align: center;
    margin-right: 10px;
    margin-top: -3vh;
    font-family: 微软雅黑;
    font-size: medium;
    font-weight: bolder;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .jybt {
    width: 26%;
    height: 48%;
    color: #FFFFFF;
    background-color: #c3152c;
    text-align: center;
    margin-top: -3vh;
    font-family: 微软雅黑;
    font-size: medium;
    font-weight: bolder;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bqy_content {
    width: 100%;
    height: 25vh;
    margin-top: -10vh;
  }

  .bqy_content .all1 {
    width: 100%;
    height: 100%;
    display: flex;
    background: url("../../assets/images/qyjjbgb.jpg") no-repeat;
    background-size: 120% 120%;
  }

  .bqy_content .all2 {
    width: 100%;
    height: 100%;
    display: flex;
    background: url("../../assets/images/xdhgc.jpg") no-repeat;
    background-size: 120% 120%;
  }

  .bqy_content .all3 {
    width: 100%;
    height: 100%;
    display: flex;
    background: url("../../assets/images/dszjy.jpg") no-repeat;
    background-size: 100% 120%;
  }

  .fzlc {
    width: 100%;
    height: 40vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: url("../../assets/images/fzlcbg.jpg") no-repeat;
    background-size: 100% 100%;
  }

  .gswh {
    width: 100%;
    height: 25vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //background-color: #FFFFFF;
    background: url("../../assets/images/numbg.jpg") no-repeat;
    background-size: 100% 100%;
  }

  .el-divider--horizontal {
    display: block;
    height: 1px;
    width: 100%;
    margin: -15px 0;
  }

  .plgd {
    width: 100%;
    height: 40vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: url("../../assets/images/numbg.jpg") no-repeat;
    background-size: 100% 100%;
  }

  /deep/ .el-carousel__container {
    height: 28vh;
  }
}

</style>
